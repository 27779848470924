import i18n from '@/locales/i18n.js'
import router from '@/router/route.js'
const submit_button_only = {
  uniqueId: 'control_button_submit',
  type: 'button',
  name: 'continue',
  label: '',
  blabel: 'ဆက်ရန် | Continue | ดำเนินต่อ',
  subLabel: '',
  isShowLabel: false,
  placeholderText: '',
  containerClass: 'col-12',
  additionalContainerClass: 'd-flex justify-content-center w-100',
  additionalFieldClass: 'rounded-pill px-5',
  additionalLabelClass: '',
  defaultValue: '',
  validations: [],
  buttonClass: 'btn btn-primary',
  buttonType: 'submit',
  emitEventCode: 'confirm',
  emitEventData: 'confirm',
  isRunValidation: true,
}

const submit_button = {
  uniqueId: 'control_button_submit',
  type: 'button',
  name: 'continue',
  label: '',
  blabel: ' ဆက်ရန် | Continue | ดำเนินต่อ ',
  subLabel: '',
  isShowLabel: false,
  placeholderText: '',
  containerClass: 'col-6',
  additionalContainerClass: 'd-flex justify-content-center w-100',
  additionalFieldClass: 'rounded-pill px-5',
  additionalLabelClass: '',
  defaultValue: '',
  validations: [],
  buttonClass: 'btn btn-primary',
  buttonType: 'submit',
  emitEventCode: 'confirm',
  emitEventData: 'confirm',
  isRunValidation: true,
}

const back_button = {
  uniqueId: 'control_back_submit',
  type: 'button',
  name: 'previous',
  label: '',
  blabel: 'ယခင်က | Previous | ย้อนกลับ',
  subLabel: '',
  isShowLabel: false,
  placeholderText: '',
  containerClass: 'col-6',
  additionalContainerClass: 'd-flex justify-content-center w-100',
  additionalFieldClass: 'rounded-pill px-5',
  additionalLabelClass: '',
  defaultValue: '',
  validations: [],
  buttonClass: 'btn btn-outline-primary',
  buttonType: 'button',
  emitEventCode: 'back',
  emitEventData: 'back',
  isRunValidation: false,
}

var formDataMixin = {
  methods: {
    bindLocale(formData, hideButton, documentRequestList) {
      const inputData = formData
      const currentRoute = router.currentRoute.name

      for (const index in inputData.sections) {
        if (inputData.sections[index].controls) {
          /**
           * documentRequestList is the list need to update
           */
          if (documentRequestList) {
            inputData.sections[index].controls = [] //remove all of current control id
            inputData.sections[index].controls.push(...documentRequestList)
            if (!hideButton) {
              if (currentRoute != 'personal-detail') {
                inputData.sections[index].controls.push('control_back_submit')
              }
              inputData.sections[index].controls.push('control_button_submit')
            }
            // add documents request control id
          } else {
            if (!hideButton) {
              if (currentRoute != 'personal-detail') {
                inputData.sections[index].controls.push('control_back_submit')
              }
              inputData.sections[index].controls.push('control_button_submit')
            }
          }
        }
      }

      if (currentRoute == 'personal-detail') {
        inputData.controls.control_button_submit = submit_button_only
      } else {
        inputData.controls.control_back_submit = back_button
        inputData.controls.control_button_submit = submit_button
      }

      for (const index in inputData.controls) {
        if (inputData.controls[index].label) {
          const label = this.changeLanguage(inputData.controls[index].label)
          inputData.controls[index].label = label
        }
        // label for button
        if (inputData.controls[index].blabel) {
          const label = this.changeLanguage(inputData.controls[index].blabel)
          inputData.controls[index].label = label
        }

        /**
         * change subLabel as dialogLabel
         * default get_hep as subLabel
         */
        if (inputData.controls[index].subLabel) {
          const temp = this.changeLanguage(inputData.controls[index].subLabel)
          inputData.controls[index].dialogLabel = temp

          const subLabel = this.$t('get_help')
          inputData.controls[index].subLabel = subLabel
        }

        if (inputData.controls[index].placeholderText) {
          const placeholderText = this.changeLanguage(inputData.controls[index].placeholderText)
          inputData.controls[index].placeholderText = placeholderText
        }

        /**
         * dropDown or checkbox or radio
         * bind item value
         */
        if (
          inputData.controls[index].type === 'dropDown' ||
          inputData.controls[index].type === 'checkbox' ||
          inputData.controls[index].type === 'radio'
        ) {
          if (inputData.controls[index].items.length > 0) {
            inputData.controls[index].items.forEach((item, i) => {
              inputData.controls[index].items[i].text = this.changeLanguage(inputData.controls[index].items[i].text)
            })
          }
        }

        /**
         * validation
         * bind validation error Message
         */
        if (inputData.controls[index].validations.length > 0) {
          inputData.controls[index].validations.forEach((item, i) => {
            inputData.controls[index].validations[i].errorMessage = this.changeLanguage(
              inputData.controls[index].validations[i].errorMessage
            )
          })
        }
      }
      return inputData
    },

    changeLanguage(data) {
      /**
       * data will be မြန်မာ | English | ประเทศไทย
       * 0 is mm
       * 1 is en
       * 2 is th
       */
      const locale = i18n.locale
      const dataArray = data.split('|')
      if (dataArray.length == 3) {
        if (locale == 'mm') {
          return dataArray[0]
        } else if (locale == 'en') {
          return dataArray[1]
        } else {
          return dataArray[2]
        }
      } else {
        return data
      }
    },

    showDialog(e) {
      var dialogMessageList = []
      var sortControlList = []
      //click position
      const currentPosition = e.currentTarget.id
      //get dialogLabel List

      for (const ii in this.formData.sections) {
        sortControlList.push(...this.formData.sections[ii].controls)
      }

      sortControlList.forEach((sortControl) => {
        for (const i in this.formData.controls) {
          if (sortControl === i) {
            dialogMessageList.push(this.formData.controls[i].dialogLabel)
          }
        }
      })

      this.$bvModal.msgBoxOk(dialogMessageList[currentPosition], {
        hideTitle: true,
        size: 'sm',
        buttonSize: 'sm',
        bodyClass: 'p-4',
        okTitle: this.$t('got_it'),
        centered: true,
        scrollable: true,
      })
    },

    onBack() {
      const currentRoute = router.currentRoute.name
      if (currentRoute == 'employment') {
        router.replace({ name: 'personal-detail' })
      }
      if (currentRoute == 'financial') {
        router.replace({ name: 'employment' })
      }
      if (currentRoute == 'file-upload') {
        router.replace({ name: 'financial' })
      }
    },

    addClickEvent() {
      setTimeout(() => {
        var getElement = null

        getElement = document.getElementsByClassName('control-label-container')
        for (var i = 0; i < getElement.length; i++) {
          if (getElement[i].querySelector('small')) {
            getElement[i].addEventListener('click', this.showDialog, false)
            getElement[i].id = i
          }
        }

        var backButton = document.getElementById('control_back_submit')
        if (backButton) {
          backButton.addEventListener('click', this.onBack, false)
        }
      }, 0)
    },

    removeZero() {
      setTimeout(() => {
        document.querySelectorAll('input[type=number]').forEach((input) => {
          if (input.value == 0) {
            input.value = ''
          }
        })
      }, 0)
    },
  },
}

export default formDataMixin
